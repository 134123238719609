
















































import useApi from "@/use/api";
import useAttachment from "@/use/attachment";
import useUser from "@/use/user";
import { defineComponent, reactive, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue'),
    MDatePicker: () => import('@/components/molecules/m-date-picker.vue')
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { axiosInstance } = useApi({ root });
    const { uploadAttachments } = useAttachment({ root })

    const form = ref()
    const isFormValid = ref()

    const loading = ref(false);
    const error = ref<null | string>(null)

    const model = reactive({
      date: root.$moment().format('YYYY-MM-DD') as any,
      company: null as any,
      file: null as any
    })

    const submit = async () => {
      error.value = null
      loading.value = true

      await form.value?.validate()

      if (isFormValid.value) {
        const formData = {
          date: model.date ?? undefined,
          company: model.company?.id ?? undefined,
          file: undefined
        }

        if (model.file) {
          await uploadAttachments([model.file])
            .then(({ data }) => {
              formData.file = data.attachments.map((attachment: { id: string }) => attachment.id)[0]
            })
            .catch(console.log)
        }

        axiosInstance
          .post('fleet-policy', formData)
          .then(() => {
            root.$router.push({ name: 'panel.fleet-policy.list' })
          })
          .catch(() => {
            error.value = 'Nie można dodać polityki flotowej'
          })
          .finally(() => loading.value = false)
      } else {
        loading.value = false
      }
    }


    return { hasAccessTo, hasManyCompanies, form, isFormValid, loading, error, model, submit };
  },
});
